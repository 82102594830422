<template>
    <div class="recommend_news f_left">
        <h2 class="recommend_title">推荐新闻</h2>
        <ul class="recommend_news_list">
            <li class="recommend_news_li" v-for="(item, index) in recommendList" :key="index" @click="seeNewsDetail(item)">
                <div  v-if="item.coverType=='VIDEO'" class="recommend_news_image" style="text-align: center;">
                     <video v-if="item.image"  :src="item.image|$DateFormatimg" style="height: 80px;
  object-fit: cover;" controls autoplay></video>
                </div>
                <div v-else>
                <!-- <span class="recommend_news_image" :style="{backgroundImage: 'url(' + item.image + ')'}"></span> -->
                <img :src="item.image|$DateFormatimg" class="recommend_news_image" alt="">
                </div>
                <div class="recommend_news_info">
                    <p class="recommend_news_title text_twoLine" v-html="item.title"></p>
                    <p class="recommend_news_views">
                        <i class="views_icon"></i>
                        <span class="views_num">{{item.hits}}</span>
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { newsInterface } from "@/config/request";
export default {
    name: 'recommend',
    data() {
        return {
            recommendList: []
        }
    },
    methods: {
        // 获取数据
        getData() {
            newsInterface.newsLists({
                mid: 0,
                rows: 1,
                size:6
            }).then(res => {
                console.log(res);
                if(res.code == 200) {
                    let recommendArr = []
                    res.data.forEach(item => {
                        let imageArr = []
                        if(item.coverImage){
                            if(item.coverType=='VIDEO'){
                                console.log(item);
                              imageArr[0]= item.coverImage
                            }else{
                                item.image = item.coverImage.split('|')
                        item.image.forEach(subItem => {
                            if(subItem != "" && subItem.indexOf("http") != -1) {
                                imageArr.push(subItem)
                            }
                        })
                        
                            }
                            recommendArr.push({
                                coverType:item.coverType,
                            id: item.id,
                            image: imageArr[0],
                            hits: item.hits,
                            title: item.title
                        })
                            
                        }
                        
                    })
                    this.recommendList = recommendArr
                }
            })
        },
        // 查看新闻详情
        seeNewsDetail(item) {
            this.$router.push({
                path: '/newsDetail',
                query: {
                    articleId: item.id
                }
            })
            this.$emit('changeNewsId', item.id)
        }
    },
    created() {
        this.getData()
    },
}
</script>
<style scoped>
    @import './style/recommend.css';
</style>