<template>
    <div class="news_search">
        <Header></Header>
        <div class="container">
            <div class="news_search_container clearfix">
                <div class="search_result_box f_left">
                    <div class="search_box">
                        <input type="text" placeholder="请输入您想搜索的内容" class="search_input" v-model="keyword" ref="searchInput" @keyup.enter="searchNews">
                        <i class="search_icon" @click="searchNews"></i>
                    </div>
                    <!-- 有搜索结果 -->
                    <ul class="result_ul_list" v-if="hasResult == true">
                        <li class="result_li" v-for="(item, index) in resultArr" :key="index" @click="toNewsDetail(item.id)">
                            <span class="result_news_image" :style="{backgroundImage: 'url(' + item.image + ')'}"></span>
                            <div class="result_news_info">
                                <h3 class="news_title" v-html="item.title"></h3>
                                <p class="news_views">
                                    <i class="views_icon"></i>
                                    <span class="views_num">{{item.hits}}</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="page_container" v-if="hasResult == true">
                        <m-pagination :currentpage="currentPage" :total="totalPage" @current-change="currentChange"></m-pagination>
                    </div>
                    <!-- 无搜索结果 -->
                    <div class="no_result_box" v-if="hasResult == false">
                        <div class="result_content">
                            <i class="no_result_icon"></i>
                            <span class="no_result_text">当前没有符合您要求的新闻哦</span>
                        </div>
                    </div>
                </div>
                <!-- 推荐新闻 -->
                <div class="recommend_news f_left">
                    <m_recommend></m_recommend>
                </div>
            </div>
        </div>
        <Footer :footerBgColor="footerBgColor"></Footer>
    </div>
</template>
<script>
import { newsInterface } from '@/config/request'
import m_recommend from '../recommend/recommend'
export default {
    name: 'newsSearch',
    components: {
        m_recommend
    },
    data() {
        return {
            footerBgColor: '#FBFBFB',
            keyword: this.$route.query.keyword, // 搜索关键字
            totalPage: 0, // 总条数
            currentPage: 1, // 当前页
            resultArr: [], // 搜素结果
            hasResult: true, // 是否有搜索结果
        }
    },
    methods: {
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            this.searchNews()
        },
        // 新闻搜索
        searchNews() {
            newsInterface.newsSearch({
                title: this.keyword,
                pageNo: this.currentPage
            }).then(res => {
                if(res.code == 1 && res.hasOwnProperty("dataList")) {
                    this.hasResult = true
                    this.totalPage = res.count
                    let result = []
                    res.dataList.forEach(item => {
                        let imageArr = []
                        item.image = item.image.split('|')
                        item.image.forEach(subItem => {
                            if(subItem != "" && subItem.indexOf("http") != -1) {
                                imageArr.push(subItem)
                            }
                        })
                        result.push({
                            id: item.articleId,
                            image: imageArr[0],
                            hits: item.hits,
                            title: item.name
                        })
                    })
                    this.resultArr = result
                } else {
                    this.hasResult = false
                }
            })
        },
        // 去新闻详情页
        toNewsDetail(newsId) {
            this.$router.push({
                path: '/newsDetail',
                query: {
                    articleId: newsId
                }
            })
        }
    },
    created() {
        this.searchNews()
    },
}
</script>
<style>
    @import './style/newsSearch.css';
</style>